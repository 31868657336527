import { Link } from "@remix-run/react";


export default function Index() {
  return (
    <div className="page">
      <ol>
        <li><Link prefetch="intent" to="meet-jesus/merchant">The Merchant</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/ambassador">The Ambassador</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/friend">The Friend</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/creator">The Creator</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/listener">The Listener</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/teacher">The Teacher</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/empowerer">The Empowerer</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/sufferer">The Sufferer</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/pardoner">The Pardoner</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/savior">The Savior</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/purifier">The Purifier</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/conqueror">The Conqueror</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/healer">The Healer</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/comissioner">The Comissioner</Link></li>
        <li><Link prefetch="intent" to="meet-jesus/liberator">The Liberator</Link></li>
      </ol>
  </div>
  );
}
